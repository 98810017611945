import { createContext, useEffect, useState } from "react";

import { buildUseCurrentBreakpointHook } from "@thelabnyc/thelabui/src/hooks/layout";

import { mediaQueries } from "./styles/media-queries";

/**
 * Hooks for components which need to react ot the current breakpoint.
 */
export const useCurrentBreakpoint = buildUseCurrentBreakpointHook(mediaQueries);

/**
 * Context used to pass down common page properties to downstream components
 */
export const pageContext = createContext<{
    title?: string;
    seoTitle?: string;
    searchDescription?: string;
}>({});

export const PageProvider = pageContext.Provider;

let isClient = false;

// The initial render on the client has to match the server to avoid
// hydration errors, so instead of checking if `typeof window === 'undefined'`
// we leave it false until `useEffect` fires for the first time
export function useIsClient() {
    const [isClientState, setIsClient] = useState(isClient);
    useEffect(() => {
        if (isClientState) return;
        isClient = true;
        setIsClient(true);
    }, []);
    return isClientState;
}

type CustomVideoElem = HTMLVideoElement & {
    mozHasAudio?: boolean;
    webkitAudioDecodedByteCount?: number;
    audioTracks?: unknown[];
};

/**
 * @param video Video element but we have to opt out of type safety because we're using non-standard dom apis
 * Copied from https://stackoverflow.com/questions/21270048/html5-video-how-to-detect-when-there-is-no-audio-track
 */
function _hasAudio(video: CustomVideoElem): boolean {
    return (
        video.mozHasAudio ||
        Boolean(video.webkitAudioDecodedByteCount) ||
        Boolean(video.audioTracks && video.audioTracks.length)
    );
}

export function useDetectAudio(
    videoRef: React.RefObject<HTMLVideoElement>,
    src: unknown,
) {
    const [hasAudio, setHasAudio] = useState(false);
    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;
        setHasAudio(_hasAudio(video));
        function detectAudio() {
            if (!video) return;
            setHasAudio(_hasAudio(video));
        }
        video.addEventListener("loadeddata", detectAudio);
        video.addEventListener("loadedmetadata", detectAudio);
        return () => {
            video.removeEventListener("loadeddata", detectAudio);
            video.removeEventListener("loadedmetadata", detectAudio);
        };
    }, [src]);
    return hasAudio;
}
