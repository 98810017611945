import ScrollTrigger from "gsap/dist/ScrollTrigger";
import React, { createRef, useEffect, useState } from "react";
import { VisuallyHidden } from "react-aria";

import { VideoChooserBlock as Value } from "@reactivated";

import { VideoChooserBlock } from "@thelabnyc/thelabui/src/components/VideoChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { useDetectAudio } from "../../utils";
import { Clickable, ClickableProps } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./index.module.scss";

export const VideoWithPlayPauseButton = ({
    value,
    wrapperAttrs,
    attrs,
    controlAttrs,
}: {
    value: Value;
    wrapperAttrs?: React.HTMLAttributes<HTMLDivElement>;
    attrs?: React.VideoHTMLAttributes<HTMLVideoElement>;
    controlAttrs?: ClickableProps;
}) => {
    const videoRef = createRef<HTMLVideoElement>();

    const [isPlayable, setIsPlayable] = useState(false);
    const [isPlaying, setIsPlaying] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isVisible, setIsVisible] = useState(false);
    const [loadVideoSources, setLoadVideoSources] = useState(false);
    const [isMuted, setIsMuted] = useState(true);

    useEffect(() => {
        if (!videoRef.current) return;
        /**
         * Don't wait for document.readyState to be "complete" if
         * the video is in view
         */
        if (isVisible) setLoadVideoSources(true);

        if (isLoading === false && isVisible === true && isPlayable === true) {
            setIsPlaying(true);
        } else {
            setIsPlaying(false);
        }
    }, [isLoading, isVisible, isPlayable]);

    useEffect(() => {
        if (!videoRef.current) return;
        if (isPlaying === true && isPlayable === true) {
            void videoRef.current.play();
        } else {
            void videoRef.current.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        // Once the video sources are loaded, we need to refresh scroll triggers for GSAP
        if (loadVideoSources) {
            ScrollTrigger.refresh();
        }
    }, [loadVideoSources]);

    const toggleIsPlaying = () => setIsPlaying((isPlaying) => !isPlaying);

    useEffect(() => {
        /**
         * Once the document is loaded without the videos, drop the sources in
         * so that the videos can load
         */
        const contentLoaded = () => {
            if (document.readyState === "complete") {
                setLoadVideoSources(true);
            }
        };

        const setPlayable = () => void setIsPlayable(true);

        document.addEventListener("readystatechange", contentLoaded);

        const callback: IntersectionObserverCallback = (entries) => {
            entries.forEach((entry) => {
                if (!(entry.target instanceof HTMLVideoElement)) return;
                setIsVisible(entry.isIntersecting);
            });
        };

        const observer = new IntersectionObserver(callback, {
            threshold: 0.1,
        });

        if (videoRef.current) {
            observer.observe(videoRef.current);
            videoRef.current.addEventListener("loadeddata", setPlayable);
        }

        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
                videoRef.current.removeEventListener("loadeddata", setPlayable);
            }

            document.removeEventListener("readystatechange", contentLoaded);
        };
    }, []);

    const modifiedValue = {
        ...value,
        sources: loadVideoSources ? value.sources : [],
    };

    const hasAudio = useDetectAudio(videoRef, modifiedValue.sources);
    return (
        <div
            {...wrapperAttrs}
            className={concatClassNames([
                styles.root,
                wrapperAttrs?.className || "",
            ])}
            style={{
                aspectRatio: modifiedValue.aspectRatio || "16/9",
            }}
        >
            {isLoading && (
                <>
                    <div className={styles.loaderOverlay} aria-hidden="true" />
                    <div className={styles.loaderContainer} aria-hidden="true">
                        <div className={styles.loader} />
                    </div>
                </>
            )}
            <VideoChooserBlock
                ref={videoRef}
                attrs={{
                    loop: true,
                    autoPlay: true,
                    controls: false,
                    playsInline: true,
                    muted: isMuted,
                    onLoadStart: () => setIsLoading(true),
                    onWaiting: () => setIsLoading(false),
                    onPlaying: () => setIsLoading(false),
                    onCanPlayThrough: () => setIsLoading(false),
                    onVolumeChange: (e) => setIsMuted(e.currentTarget.muted),
                    ...attrs,
                }}
                value={modifiedValue}
            />
            <div
                className={concatClassNames([
                    styles.controls,
                    controlAttrs?.className || "",
                ])}
            >
                <Clickable
                    disabled={isLoading}
                    onPress={toggleIsPlaying}
                    {...controlAttrs}
                    className={styles.button}
                >
                    <VisuallyHidden>
                        {isPlaying ? "Pause" : "Play"} Video
                    </VisuallyHidden>
                    <Svg
                        name={isPlaying ? "pause" : "play-fill"}
                        className={styles.icon}
                        aria-hidden="true"
                    />
                </Clickable>
                {hasAudio && (
                    <Clickable
                        disabled={isLoading}
                        onPress={() => setIsMuted(!isMuted)}
                        {...controlAttrs}
                        className={concatClassNames([
                            styles.button,
                            controlAttrs?.className || "",
                        ])}
                    >
                        <VisuallyHidden>
                            {isMuted ? "Unmute" : "Mute"} Video
                        </VisuallyHidden>
                        <Svg
                            name={isMuted ? "audio-off" : "audio-on"}
                            className={styles.icon}
                            aria-hidden="true"
                        />
                    </Clickable>
                )}
            </div>
        </div>
    );
};
